
import Changelog from "../components/changelog/changelog";
import { Col, Row } from 'react-bootstrap';;

function ChangelogPage() {
    return (
        <Row style={{ marginBottom: '3rem'}}>
            <Col>
                <h1>Changelog</h1>
                <Changelog />
            </Col>
        </Row>
    )
}

export default ChangelogPage
