import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectSelectedProfile } from '../_slices/sitesSlice'; // all the data related with Sites
import SitesSelect from '../components/sites/SitesSelect';
import styled from 'styled-components';
import Parameters from '../components/sites/Parameters';
// UI
import { Col, Row } from 'react-bootstrap';

const Subtitle = styled.p`
    font-size: 1.5rem;
    font-weight:300;
    margin-top: .5rem;
    margin-bottom: 2.5rem;
`

const Heading2 = styled.h2`
    font-size: 2rem;
    margin-bottom: 1rem;
`

const Heading3 = styled.h2`
    font-size: 1.3rem;
    margin-bottom: 1rem;
`

const PWrapper = styled.section `
    p {
        line-height: 2.5rem
    }
`

function SiteInfo() {

    // sites info
    const selectedProfile = useSelector(selectSelectedProfile);
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;
    const siteOwner = selectedProfile?.associated_site?.owner_name ? selectedProfile?.associated_site?.owner_name : firstProfile?.associated_site?.owner_name;
    const partnerSince = selectedProfile?.associated_site?.partners_since ? selectedProfile?.associated_site?.partners_since : firstProfile?.associated_site?.partners_since;
    const partnerSinceDate = moment(partnerSince).format('D MMM YYYY')
    const Privilege = selectedProfile?.profile_info?.privilege_group ? selectedProfile?.profile_info?.privilege_group : firstProfile?.profile_info?.privilege_group;
    const Department = selectedProfile?.profile_info?.department ? selectedProfile?.profile_info?.department : firstProfile?.profile_info?.department;

    return (
        <>
            <h1>{siteName}</h1>
            <Subtitle>
                Find here all the information available for Sites.
            </Subtitle>

            <Row style={{ marginBottom: '3rem' }}>
                <Col md={3} sm={5}>
                    <Heading3>Change site</Heading3>
                    <SitesSelect />
                    <br />
                </Col>
                <Col md={9} sm={7}>
                    <Heading2>Site information</Heading2>
                    <p>
                        <strong>Name:</strong> {siteName}
                    </p>
                    <p>
                        <strong>Owner:</strong> {siteOwner}
                    </p>
                    <p style={{marginBottom: '2rem'}}>
                        <strong>Partners since:</strong> {partnerSinceDate}
                    </p>
                    {/* <p>
                        <strong>Privilege Group:</strong> {Privilege}
                    </p>
                    <p style={{marginBottom: '2rem'}}>
                        <strong>Department:</strong> {Department}
                    </p> */}

                    <Heading2>Site Parameters</Heading2>
                    <PWrapper>
                        <Parameters />
                    </PWrapper>
                </Col>
            </Row>

        </>
    )
}

export default SiteInfo;
