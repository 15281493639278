import { Row, Col } from 'react-bootstrap';

// Boxes
import InfoBox01 from '../components/infoBoxes/infoBox-01';
import InfoBox02 from '../components/infoBoxes/infoBox-02';
import InfoBox03 from '../components/infoBoxes/infoBox-03';

function Boxes() {
    return (
        <Row>
            <Col>
                <InfoBox01 />
            </Col>
            <Col>
                <InfoBox02 />
            </Col>
            <Col>
            <InfoBox03 />
            </Col>
        </Row>
    )

}
export default Boxes;
