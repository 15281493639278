export const colors = {
    primary: {
      light: '#F2E4F7',
      main: '#750F7D',
      dark: '#600C66',
      contrastText: '#fff'
    },
    secondary: {
      light: '#23D3C5',
      main: '#04BAAB',
      dark: '#0AA497',
      contrastText: '#fff'
    },
    success: {
      light: '#00703C',
      main: '#04BAAB',
      dark: '#0AA497',
      contrastText: '#0AA497',
    },
    text: {
      primary: '#333333',
      secondary:'#565656',
      disabled: '#B1B4B6'
    }
      }
