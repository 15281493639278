import { useSelector } from 'react-redux';
import { selectAuth } from './_slices/authSlice';
// import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { HashRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { PrivateRoute } from './components/routes/PrivateRoute';

// Layout
import Header from './layout/Header';
import Footer from './layout/Footer';

// Pages
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import RevenuePage from './pages/Revenue';
import SchedulePage from './pages/Schedule';
import SiteInfo from './pages/SiteInfo';
import ChangelogPage from './pages/ChangelogPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />

          <Route path="/revenue"
            element={
              <PrivateRoute>
                <RevenuePage />
              </PrivateRoute>
            }
          />

          <Route path="/schedule"
            element={
              <PrivateRoute>
                <SchedulePage />
              </PrivateRoute>
            }
          />

          <Route path="/site"
            element={
              <PrivateRoute>
                <SiteInfo />
              </PrivateRoute>
            }
          />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/changelog"
            element={
              <PrivateRoute>
                <ChangelogPage />
              </PrivateRoute>
            }
          />

        </Route>
      </Routes>
    </Router>
  );
}

function Layout() {
  const auth = useSelector(selectAuth);
  const isAuth = auth.isAuthenticated;
  return (
    <>
      {isAuth && <Header />}
      <main className='container-xxl'>
        <Outlet />
      </main>
      {isAuth && <Footer />}
    </>
  );
}
export default App;
