import { useState } from 'react';
import { useSelector } from 'react-redux';
import SitesSelect from '../components/sites/SitesSelect';
import { selectProfileToken } from '../_slices/profileTokenSlice';

// UI
import { Tab, Tabs } from 'react-bootstrap';
import { HomeLoader } from '../_helpers/loaders/homeLoader';
import styled from 'styled-components';

// charts
import DailyRevenue from '../components/charts/Revenue/dailyRevenue';
import TotalChart from '../components/charts/Revenue/totalRevenue';

const FlexTabs = styled(Tabs)`
    border-color: #750F7D;
    .nav-link {
        color: #750F7D;
        &.active {
            border-radius: 0;
            color: #750F7D;
            border-color: #750F7D;
            border-bottom-color: transparent !important;
        }
    }
`

const FlexTab = styled(Tabs)`
    border-color: #750F7D;
`



function RevenuePage() {
    const profileToken = useSelector(selectProfileToken);
    const [activeTab, setActiveTab] = useState('daily');

    return (
        <>
            <div style={{ display: 'none' }}><SitesSelect /></div>
            {!profileToken ? <HomeLoader /> :
                <FlexTabs
                    id="revenue-tabs"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                >
                    <FlexTab eventKey="daily" title="Daily Revenue">
                        {activeTab === 'daily' && <DailyRevenue />}
                    </FlexTab>
                    <FlexTab eventKey="total" title="Monthly Revenue">
                        {activeTab === 'total' && <TotalChart />}
                    </FlexTab>
                </FlexTabs>
            }
        </>
    )
}

export default RevenuePage;
