export const chartColors = {
    // Merged List
    auctionTrade: '99, 110, 250', //DA // ##636EFA
    balancingMechanism: '0, 172, 162', // #00ACA2
    BM: '255, 152, 0', // #00ACA2
    BMBid: '171, 99, 250', // ##AB63FA
    BMBuy: '0, 172, 162', // #00ACA2
    BMOffer: '243, 155, 88', // ##FFA15A
    BMSell: '0, 172, 162', // #00ACA2
    DA: '99, 110, 250', // #636EFA
    DABuy: '99, 110, 250', // #636EFA
    DASell: '99, 110, 250', // #636EFA
    Capacity: '113, 189, 109', // #0A5F89
    DC: '239, 85, 59', // #FF6692
    DCH: '210, 70, 47', // #FF6692
    DCL: '239, 85, 59', // #FF6692
    DirectPower: '10, 95, 137', // #0A5F89
    DLH: '74, 36, 201', // #4A24C9
    DM: '182, 232, 128', // #B6E880
    DMH: '141, 203, 74', // #B6E880
    DML: '182, 232, 128', // #B6E880
    DR: '255, 151, 255', // #FF97FF
    DRH: '255, 151, 255', // #FF97FF
    DRL: '255, 151, 255', // #FF97FF
    energyTrade: '255, 152, 0', // #FF9800
    flexTrade: '62, 94, 210', // #FF9800
    FFR: '25, 211, 243', // #19D3F3
    frequencyService: '10, 95, 137', // #0A5F89
    ForwardBuy: '18, 205, 129', // #12CD81
    ForwardSell: '18, 205, 129', // #12CD81
    imbalance: '0, 0, 0', // #000000
    imbalancePriceResponse: '0, 0, 0', // #000000
    ImbalanceLong: '0, 0, 0', // #000000
    ImbalanceShort: '0, 0, 0', // #000000
    intradayTrade: '239, 85, 59', // WD // #EF553B
    LFS: '238, 194, 15', // #EEC20F
    NonCommodity: '0, 206, 231', // #00CEE7
    Other: '0, 204, 150', // #00CC96
    SocBalancing: '104, 0, 255', // #6800FF
    Trade: '99, 110, 250', //DA // ##636EFA
    unavailable: '189, 189, 189', // #BDBDBD
    WD: '239, 85, 59', //  #EF553B
    WDBuy: '239, 85, 59', //  #EF553B
    WDSell: '239, 85, 59', //  #EF553B
}

export const chartOpacity = {
    low: '0.25',
    medium: '0.5',
    high: '0.85',
    full: '1',
}
