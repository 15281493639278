import { useSelector } from 'react-redux';
import SitesSelect from '../components/sites/SitesSelect';
import { selectProfileToken } from '../_slices/profileTokenSlice';
// UI
import { HomeLoader } from '../_helpers/loaders/homeLoader';
// charts
import ScheduleChart from '../components/charts/schedule/schedlueChart';


function SchedulePage() {
    const profileToken = useSelector(selectProfileToken);

    return (
        <>
            <div style={{ display: 'none' }}><SitesSelect /></div>
            {!profileToken ? <HomeLoader /> :
                <>
                    <ScheduleChart />
                </>
            }
        </>
    )
}

export default SchedulePage;
