import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { profileType } from '@/src/_types/profileType';
import axios from 'axios';
import { baseAPIUrlAuth } from '../config';

interface ProfileTokenState {
  accessToken: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProfileTokenState = {
  accessToken: null,
  loading: false,
  error: null,
};

type RootState = any;

export const profileTokenSlice = createSlice({
  name: 'profileToken',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.accessToken = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { startLoading, setAccessToken, setError } = profileTokenSlice.actions;
export const selectProfileToken = (state: RootState) => state.profileToken.accessToken;
export default profileTokenSlice.reducer;


export const fetchProfileToken = (): AppThunk => async (dispatch, getState) => {
  const auth = getState().auth;
  const selectedProfile = getState().selectedProfile.selectedProfile;

  // Access profile_id directly from profile_info inside selectedProfile
  const firstProfile = selectedProfile?.authorized_profiles?.[0];
  const profileID = selectedProfile?.profile_info?.profile_id ?? firstProfile?.profile_info?.profile_id;

  if (!profileID) return; // Exit early if no profileID is found

  dispatch(startLoading());

  try {
    const config = {
      headers: {
          'Content-Type': 'application/json',
      }
  };
    const requestBody = {
      username: auth.username, // will be delete it
      password: auth.password, // will be delete it
      // accessToken: auth.accessToken // will be added it when we delete user and pass
      profile_id: profileID,
    };

    const response = await axios.post<profileType>(`${baseAPIUrlAuth}/authorize/profile-token`, requestBody, config);

    dispatch(setAccessToken(response.data.access_token));
    localStorage.setItem('localProfileToken', JSON.stringify(response.data.access_token));

  } catch (err) {
    if (err instanceof Error) {
      dispatch(setError(err.message));
    } else {
      dispatch(setError('An error occurred while fetching the profile token.'));
    }
  }
};
