import { Link } from 'react-router-dom';

// images and icons
import LogoFooter from '../assets/images/logo-white.svg';
import IconLinkedin from '../assets/icons/social/icon-linkedin.svg';
import IconYoutube from '../assets/icons/social/icon-youtube.svg';
import IconTwitter from '../assets/icons/social/icon-twitter.svg';

function Footer() {
    return (
        <footer className="footer">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer__logo-container">
                            <img className="logo logo--footer" src={LogoFooter} alt="Flexitricity" />
                        </div>
                        <p className="footer__logo-text">Member of <a href="https://flexassure.org.uk/" title="Flex Assure"
                            target="_blank" rel="noreferrer">Flex Assure</a></p>
                        <div className="social">
                            <ul className="list-unstyled list-inline">
                                <li className="list-inline-item">
                                    <a
                                        href="https://www.linkedin.com/company/flexitricity/"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <img src={IconLinkedin} alt="Follow us on Linkedin" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        href="https://www.youtube.com/user/FlexitricityLtd"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <img src={IconYoutube} alt="Follow us on Youtube" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://twitter.com/Flexitricity"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <img src={IconTwitter} alt="Follow us on Twitter" />
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-md-2 col-xs-12">
                        <h2>Resources</h2>
                        <nav>
                            <ul>
                                <li>
                                    <a
                                        href="https://flexitricity.com/"
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        Main Website
                                    </a>
                                </li>
                                <li>
                                    <Link to="/schedule">Schedule</Link>
                                </li>
                                <li>
                                    <Link to="/revenue">Revenue</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="footer__secondary">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <p>© 2023 Flexitricity
                                <Link to="/changelog" style={{ color: '#aaa', marginLeft: '1rem' }}>v0.3.8</Link>
                            </p>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <nav className="text-end">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                        <a
                                            href="https://flexitricity.com/contact/"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
