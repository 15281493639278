import { useSelector } from 'react-redux';
import { selectSelectedProfile } from '../../_slices/sitesSlice';
// import { selectProfileToken } from '../../_slices/profileTokenSlice';
import Parameters from '../sites/Parameters';

import { Box } from "../../_styles/Box";
import { boxType } from "@/src/_types/boxType";
import { colors } from "../../_styles/colors";
import { LoaderInfoBox02 } from '../../_helpers/loaders/infoBoxLoaders';

function InfoBox02(
    {
        $backgroundColor
    }: boxType
) {

    // const profileToken = useSelector(selectProfileToken);
    // Get Site information
    const selectedProfile = useSelector(selectSelectedProfile);
    // Select first profile
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;

    return (
        <Box $backgroundColor={$backgroundColor || colors.secondary.dark}>
            {
                !siteName ?
                    <LoaderInfoBox02 />
                    :
                    <>
                        <h2>Site Parameters</h2>
                        <Parameters />
                    </>
            }

        </Box>
    )
}

export default InfoBox02;
