import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { fetchProfileToken } from './profileTokenSlice';
import axios from 'axios';
import { baseAPIUrlAuth } from '../config';
import { sitesProps } from '@/src/_types/sitesType';

 // Function to get the persisted profile v0.3.5.7
 function getPersistedProfile(): sitesProps | null {
    const profileString = localStorage.getItem('selectedProfile');
    if (profileString) {
        try {
            const profile: sitesProps = JSON.parse(profileString);
            return profile;
        } catch (error) {
            console.error("Error parsing the selected profile from localStorage", error);
            return null;
        }
    }
    return null;
}


export const fetchSites = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    // v0.3.5.7
    // const { selectedProfile, isInitialLoad } = state.selectedProfile;


    const auth = getState().auth;
    const accessToken = auth.accessToken;
    dispatch(startLoading());

    try {
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        };

        const response = await axios.get<sitesProps>(`${baseAPIUrlAuth}/authorize/profiles`, config);
        dispatch(setData(response.data));


        // v0.3.5.7
        const persistedProfile = getPersistedProfile();
        if (persistedProfile) {
            dispatch(setSelectedProfile(persistedProfile));
        } else if (response.data.authorized_profiles.length > 0) {
            dispatch(setSelectedProfile({ authorized_profiles: [response.data.authorized_profiles[0]] }));
        }
        dispatch(fetchProfileToken());



    } catch (err) {
        if (err instanceof Error) {
            dispatch(setError(err.message));
        } else {
            dispatch(setError('An error occurred while fetching data.'));
        }
    }
};

type RootState = any;

interface SitesState {
    selectedProfile: sitesProps | null;
    data: sitesProps | null;
    loading: boolean;
    error: string | null;
    // v0.3.5.7
    // isInitialLoad: boolean;
}

const initialState: SitesState = {
    selectedProfile: {
        authorized_profiles: [],
    },
    data: null,
    loading: false,
    error: null,
    // v0.3.5.7
    // isInitialLoad: true,
};

export const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setSelectedProfile: (state, action: PayloadAction<sitesProps>) => {
            state.selectedProfile = action.payload;
        },
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setData: (state, action: PayloadAction<sitesProps>) => {
            state.loading = false;
            state.data = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        // v0.3.5.7
        // setInitialLoad: (state, action: PayloadAction<boolean>) => {
        //     state.isInitialLoad = action.payload;
        // },
    },
});


export const { setSelectedProfile, startLoading, setData, setError } = sitesSlice.actions;
// v0.3.5.7
// export const { setSelectedProfile, startLoading, setData, setError, setInitialLoad } = sitesSlice.actions;
export const selectSelectedProfile = (state: RootState) => state.selectedProfile.selectedProfile;
export default sitesSlice.reducer;
