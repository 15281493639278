import styled from "styled-components";
import { boxType } from "@/src/_types/boxType";
import { colors } from "./colors";

export const Box = styled.section<boxType>`
overflow: hidden;
position: relative;
padding: 1.5rem;
height:100%;
background-color: ${props => props.$backgroundColor || colors.primary.main}; // default to red if no prop is provided
&:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  top: -85px;
  right: -95px;
  @media all and (max-width:768px) {
    top: -105px;
    right: -140px;
  }
}

&:before {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  top: -125px;
  right: -15px;
  opacity: 0.5;
  @media all and (max-width:768px) {
    top: -155px;
    right: -70px;
  }
}

* {
  color: #fff;
}

h2 {
  font-size: 1.4rem;
  margin-bottom: .5rem;
  letter-spacing: 0.5px;
}

p {
  font-size: .9rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.5px;
}
}
.box-btn {
  display: inline-flex;
  margin-top: 1rem;
  text-decoration: none;
}

`
