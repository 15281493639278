import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../../_slices/authSlice';
import { logout } from '../../../_slices/authSlice'
import { Link, useNavigate } from 'react-router-dom';

import { userHeaderType } from '@/src/_types/userType';

// UI
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SingleUser from '../../../assets/icons/user/single-user.svg';

// styles
import {
    UserHeaderWrapper
} from './userHeaderStyle'

function UserHeader(
    {
        alignRight = true
    }: userHeaderType
) {
    // get user info
    const auth = useSelector(selectAuth);
    const username = auth.username;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Greeting Function
    var date = new Date();
    var hours = date.getHours();
    let message;

    if (hours < 12) {
        message = "Good Morning";
    } else if (hours < 18) {
        message = "Good Afternoon";
    } else {
        message = "Good Evening";
    }

    // Logout
    const handleLogout = () => {
        dispatch(logout());
        dispatch({ type: 'RESET_STORE' });
        // navigate('/login');
        navigate('login');
    };

    useEffect(() => {
        // Retrieve the last login timestamp from localStorage
        const lastLoginTimestampString = localStorage.getItem('lastLogin');
        if (lastLoginTimestampString) {
            const lastLoginTimestamp = +lastLoginTimestampString; // Convert string to number
            const currentTime = Date.now();
            const elapsedTime = currentTime - lastLoginTimestamp;

            // Calculate remaining time for the 1-hour limit
            const remainingTime = 3600000 - elapsedTime; // 1 hour = 3,600,000 milliseconds

            if (remainingTime > 0) {
                // Set up the auto-logout timer for the remaining time
                const logoutTimeout = setTimeout(() => {
                    handleLogout();
                }, remainingTime);

                // Clear the timeout when the component unmounts or if lastLogin changes
                return () => clearTimeout(logoutTimeout);
            } else {
                // If more than 1 hour has already passed, logout immediately
                handleLogout();
            }
        }
    }, [dispatch, handleLogout]);




    return (
        <UserHeaderWrapper>
            <NavDropdown
                title={
                    <img src={SingleUser} alt='Login' />
                }
                id="collapsible-nav-dropdown"
            >
                <Dropdown.Header>{message} {username}</Dropdown.Header>
                <NavDropdown.Item as={Link} to="/site">
                    Site info
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={handleLogout}>
                    Logout
                </NavDropdown.Item>
            </NavDropdown>
        </UserHeaderWrapper>
    )
}

export default UserHeader;
