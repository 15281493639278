import { LoaderInfoBox01 } from '../../_helpers/loaders/infoBoxLoaders';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectSelectedProfile } from '../../_slices/sitesSlice';
// import { selectProfileToken } from '../../_slices/profileTokenSlice';
import { Box } from "../../_styles/Box";

function InfoBox01() {

    // const profileToken = useSelector(selectProfileToken);

    // Get Site information
    const selectedProfile = useSelector(selectSelectedProfile);
    // Select first profile
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;
    const siteOwner = selectedProfile?.associated_site?.owner_name ? selectedProfile?.associated_site?.owner_name : firstProfile?.associated_site?.owner_name;
    const partnerSince = selectedProfile?.associated_site?.partners_since ? selectedProfile?.associated_site?.partners_since : firstProfile?.associated_site?.partners_since;
    const partnerSinceDate = moment(partnerSince).format('D MMM YYYY')

    return (
        <Box>
            {
                !siteName ?
                    <LoaderInfoBox01 />
                    :
                    <>
                        <h2>Site info</h2>
                        <p>
                            <strong>Name:</strong> {siteName} <br />
                            <strong>Owner:</strong> {siteOwner} <br />
                            <strong>Partners since:</strong> {partnerSinceDate}
                        </p>
                    </>
            }

        </Box>
    )
}

export default InfoBox01;
