import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: null,
    password: null,
    accessToken: null,
    expiresIn: null,
    tokenType: null,
    isAuthenticated: false
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.accessToken = action.payload.access_token;
      state.expiresIn = action.payload.access_token_expires_in;
      state.tokenType = action.payload.token_type;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.username = null;
      state.password = null;
      state.accessToken = null;
      state.expiresIn = null;
      state.tokenType = null;
      state.isAuthenticated = false;
    }
  }
});

export const { loginSuccess, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
