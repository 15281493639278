export const commonOptions = {
    chart: {
        stacked: true,
        toolbar: {
            tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
            }
        }
    },
    fill: {
        type: "solid",
    },
    tooltip: {
        enabled: true,
        shared: false,
        followCursor: true,
        intersect: true,
        inverseOrder: false,
        fillSeriesColor: false,
        theme: 'dark',
        marker: {
            show: false,
        }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '95%',
        }
    },
    legend: {
        show: true,
        showForNullSeries: false,
        showForZeroSeries: false,
    },
    dataLabels: {
        enabled: false
    },
}
