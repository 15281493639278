import styled from "styled-components";
import { colors } from "../../../_styles/colors";

export const ChartFilter = styled.div `
    background-color: ${colors.primary.light};
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0;
    display: flex;
`;
export const SitesContainer = styled.div `
    width: 100%;

    .SitesSelector {

        > div {
            min-height: 52px;
            border-radius: 0;
        }
    }
`;

export const ChartTitle = styled.div `
    margin-top: 2rem;
    margin-bottom: 1rem;
    h2 {}
    h3 {
        font-weight: 100;
        font-size: 1.4rem;
    }
`

export const ChartDateWrapper = styled.p `
    text-align: right;
    color: #787878;
    font-size: .95rem;
}
`
