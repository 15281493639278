import { useSelector } from 'react-redux';
import { selectAuth } from '../../_slices/authSlice';
import { selectProfileToken } from '../../_slices/profileTokenSlice';
import { selectSelectedProfile } from '../../_slices/sitesSlice';

import { Box } from "../../_styles/Box";
import { boxType } from "@/src/_types/boxType";
import { LoaderInfoBox03 } from '../../_helpers/loaders/infoBoxLoaders';



function InfoBox03(
    {
        $backgroundColor
    }: boxType
) {

    // Get user info
    const auth = useSelector(selectAuth);
    const username = auth.username;
    const profileToken = useSelector(selectProfileToken);


    // Get Site information
    const selectedProfile = useSelector(selectSelectedProfile);
    // Select first profile
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const Privilege = selectedProfile?.profile_info?.privilege_group ? selectedProfile?.profile_info?.privilege_group : firstProfile?.profile_info?.privilege_group;
    const Department = selectedProfile?.profile_info?.department ? selectedProfile?.profile_info?.department : firstProfile?.profile_info?.department;

    return (
        <Box $backgroundColor={$backgroundColor || '#1e88e5'}>
            {
                !profileToken ?
                    <LoaderInfoBox03 />
                    :
                    <>
                        <h2>Hello, {username}!</h2>
                        <p>
                            <strong>Privilege Group:</strong> {Privilege} <br />
                            <strong>Department:</strong> {Department}
                        </p>
                    </>
            }
        </Box>
    )
}

export default InfoBox03;
