import { servicesChart } from './services';
import { chartColors, chartOpacity } from '../../../_styles/chartStyles';

import styled from 'styled-components';

export const ScheduleColorsWrapper = styled.div`
    .scheduleChart .apexcharts-canvas {
        ${Object.entries({
            [servicesChart.auctionTrade]: chartColors.auctionTrade,
            [servicesChart.balancingMechanism]: chartColors.balancingMechanism,
            [servicesChart.BM]: chartColors.BM,
            [servicesChart.BMBid]: chartColors.BMBid,
            [servicesChart.BMBuy]: chartColors.BMBuy,
            [servicesChart.BMOffer]: chartColors.BMOffer,
            [servicesChart.BMSell]: chartColors.BMSell,
            [servicesChart.DA]: chartColors.DA,
            [servicesChart.DABuy]: chartColors.DABuy,
            [servicesChart.Capacity]: chartColors.Capacity,
            [servicesChart.DASell]: chartColors.DASell,
            [servicesChart.DC]: chartColors.DC,
            [servicesChart.DCH]: chartColors.DCH,
            [servicesChart.DCHigh]: chartColors.DCH,
            [servicesChart.DCL]: chartColors.DCL,
            [servicesChart.DCLow]: chartColors.DCL,
            [servicesChart.DirectPower]: chartColors.DirectPower,
            [servicesChart.DLH]: chartColors.DLH,
            [servicesChart.DM]: chartColors.DM,
            [servicesChart.DMH]: chartColors.DMH,
            [servicesChart.DML]: chartColors.DML,
            [servicesChart.DR]: chartColors.DR,
            [servicesChart.DRH]: chartColors.DRH,
            [servicesChart.DRL]: chartColors.DRL,
            [servicesChart.energyTrade]: chartColors.energyTrade,
            [servicesChart.flexTrade]: chartColors.flexTrade,
            [servicesChart.FFR]: chartColors.FFR,
            [servicesChart.frequencyService]: chartColors.frequencyService,
            [servicesChart.ForwardBuy]: chartColors.ForwardBuy,
            [servicesChart.ForwardSell]: chartColors.ForwardSell,
            [servicesChart.imbalancePriceResponse]: chartColors.imbalancePriceResponse,
            [servicesChart.ImbalanceLong]: chartColors.ImbalanceLong,
            [servicesChart.ImbalanceShort]: chartColors.ImbalanceShort,
            [servicesChart.intradayTrade]: chartColors.intradayTrade,
            [servicesChart.LFS]: chartColors.LFS,
            [servicesChart.NonCommodity]: chartColors.NonCommodity,
            [servicesChart.Other]: chartColors.Other,
            [servicesChart.SocBalancing]: chartColors.SocBalancing,
            [servicesChart.Trade]: chartColors.Trade,
            [servicesChart.unavailable]: chartColors.unavailable,
            [servicesChart.WD]: chartColors.WD,
            [servicesChart.WDBuy]: chartColors.WDBuy,
            [servicesChart.WDSell]: chartColors.WDSell,
        }).map(([seriesName, color]) => `
            g[seriesName="${seriesName}"] path:first-of-type {
                fill: rgba(${color}, ${chartOpacity.full});
            }
        `).join('')}

        .apexcharts-legend-series {
            ${Object.entries({
                [servicesChart.auctionTrade]: chartColors.auctionTrade,
                [servicesChart.balancingMechanism]: chartColors.balancingMechanism,
                [servicesChart.BM]: chartColors.BM,
                [servicesChart.BMBid]: chartColors.BMBid,
                [servicesChart.BMBuy]: chartColors.BMBuy,
                [servicesChart.BMOffer]: chartColors.BMOffer,
                [servicesChart.BMSell]: chartColors.BMSell,
                [servicesChart.DA]: chartColors.DA,
                [servicesChart.DABuy]: chartColors.DABuy,
                [servicesChart.DASell]: chartColors.DASell,
                [servicesChart.Capacity]: chartColors.Capacity,
                [servicesChart.DC]: chartColors.DC,
                [servicesChart.DCH]: chartColors.DCH,
                [servicesChart.DCHigh]: chartColors.DCH,
                [servicesChart.DCL]: chartColors.DCL,
                [servicesChart.DCLow]: chartColors.DCL,
                [servicesChart.DirectPower]: chartColors.DirectPower,
                [servicesChart.DLH]: chartColors.DLH,
                [servicesChart.DM]: chartColors.DM,
                [servicesChart.DMH]: chartColors.DMH,
                [servicesChart.DML]: chartColors.DML,
                [servicesChart.DR]: chartColors.DR,
                [servicesChart.DRH]: chartColors.DRH,
                [servicesChart.DRL]: chartColors.DRL,
                [servicesChart.energyTrade]: chartColors.energyTrade,
                [servicesChart.flexTrade]: chartColors.flexTrade,
                [servicesChart.FFR]: chartColors.FFR,
                [servicesChart.frequencyService]: chartColors.frequencyService,
                [servicesChart.ForwardBuy]: chartColors.ForwardBuy,
                [servicesChart.ForwardSell]: chartColors.ForwardSell,
                [servicesChart.imbalancePriceResponse]: chartColors.imbalancePriceResponse,
                [servicesChart.ImbalanceLong]: chartColors.ImbalanceLong,
                [servicesChart.ImbalanceShort]: chartColors.ImbalanceShort,
                [servicesChart.intradayTrade]: chartColors.intradayTrade,
                [servicesChart.LFS]: chartColors.LFS,
                [servicesChart.NonCommodity]: chartColors.NonCommodity,
                [servicesChart.Other]: chartColors.Other,
                [servicesChart.SocBalancing]: chartColors.SocBalancing,
                [servicesChart.Trade]: chartColors.Trade,
                [servicesChart.unavailable]: chartColors.unavailable,
                [servicesChart.WD]: chartColors.WD,
                [servicesChart.WDBuy]: chartColors.WDBuy,
                [servicesChart.WDSell]: chartColors.WDSell,
            }).map(([seriesName, color]) => `
                &[seriesName="${seriesName}"] .apexcharts-legend-marker {
                    border-color: rgba(${color}, 1) !important;
                }
            `).join('')}

            .apexcharts-legend-marker {
                border-width: 6px !important;
            }
        }
    }
`;
