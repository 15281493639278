import changelogData from './data.json'
import styled from 'styled-components';

const H2 = styled.h2 `
    font-size: 1.5rem;
    font-weight:100;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;
const H3 = styled.h3 `
    font-size: 1.3rem;
    margin-top: 2.5rem;
    margin-bottom: .5rem;
`
const H4 = styled.h4 `
    font-size: 1rem
`

export default function Changelog() {
    return (
        <div>
            <H2>See the latest Enhancements and Features on Flexitricity Portal</H2>
            {changelogData.changelog.map((update, index) => (
                <div key={index}>
                    <H3>Version {update.version} ({update.date})</H3>
                    { (update?.newFeatures?.length ?? 0) > 0 && <H4>New Features</H4> }
                    <ul>
                        {update?.newFeatures?.map((feature, idx) => (
                            <li key={idx}>{feature}</li>
                        ))}
                    </ul>

                    { (update?.enhancements?.length ?? 0) > 0 && <H4>Enhancements</H4> }
                    <ul>
                        {update?.enhancements?.map((enhancement, idx) => (
                            <li key={idx}>{enhancement}</li>
                        ))}
                    </ul>
                    { (update?.bugFixes?.length ?? 0) > 0 && <H4>Bug Fixes</H4> }
                    <ul>
                        {update?.bugFixes?.map((bug, idx) => (
                            <li key={idx}>{bug}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}
