import thunk from 'redux-thunk';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import { Action } from 'redux';
import { configureStore, combineReducers, getDefaultMiddleware  } from '@reduxjs/toolkit';
import authReducer from './_slices/authSlice';
import selectedProfileReducer from './_slices/sitesSlice';
import profileTokenReducer from './_slices/profileTokenSlice';

// new
const persistConfig = {
  key: 'root',
  storage,
};

const combinedReducer = combineReducers({
  auth: authReducer,
  selectedProfile: selectedProfileReducer,
  profileToken: profileTokenReducer,
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export type RootState = ReturnType<typeof combinedReducer>;

// new
const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/REGISTER', 'persist/FLUSH'],
    },
  }).concat(thunk),
});

export const persistor = persistStore(store);
