export const servicesChart = {
    auctionTrade: 'AuctionxTrade', //DA
    balancingMechanism: 'BalancingxMechanism', // BM
    BM: 'BM',
    BMBid: 'BMxBid',
    BMBuy: 'BMxBuy',
    BMOffer: 'BMxOffer',
    BMSell: 'BMxSell',
    DA: 'DA',
    DABuy: 'DAxBuy',
    DASell: 'DAxSell',
    Capacity: 'Capacity', // no change
    DC: 'DC',
    DCH: 'DCH',
    DCHigh: 'DCxHigh',
    DCL: 'DCL',
    DCLow: 'DCxLow',
    DirectPower: 'DirectxPower',
    DLH: 'DLH',
    DM: 'DM',
    DMH: 'DMxHigh',
    DML: 'DMxLow',
    DR: 'DR',
    DRH: 'DRxHigh',
    DRL: 'DRxLow',
    energyTrade: 'EnergyxTrade',
    flexTrade: 'FlexxTrade',
    FFR: 'FFR',
    frequencyService: 'FrequencyxService', // FFR
    ForwardBuy: 'ForwardxBuy',
    ForwardSell: 'ForwardxSell',
    imbalancePriceResponse: 'ImbalancexPricexResponse',
    ImbalanceLong: 'ImbalancexLong',
    ImbalanceShort: 'ImbalancexShort',
    intradayTrade: 'IntradayxTrade', // WD
    LFS: 'LFS',
    NonCommodity: 'NonxCommodity',
    Other: 'Other',
    SocBalancing: 'SocBalancing',
    Trade: 'Trade', //DA
    unavailable: 'Unavailable', // no change
    WD: '',
    WDBuy: 'WDxBuy',
    WDSell: 'WDxSell'
}
