import { useSelector } from 'react-redux';
import { selectSelectedProfile } from '../_slices/sitesSlice';
import { Link } from 'react-router-dom';
import SitesSelect from '../components/sites/SitesSelect';
import { selectProfileToken } from '../_slices/profileTokenSlice';
import Boxes from '../layout/Boxes';
// UI
import { HomeLoader } from '../_helpers/loaders/homeLoader';
import { Button } from 'react-bootstrap';

// charts
import ScheduleChart from '../components/charts/schedule/schedlueChart';

// import Parameters from '../components/sites/Parameters';


function HomePage() {
    const profileToken = useSelector(selectProfileToken);
    const selectedProfile = useSelector(selectSelectedProfile);
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;

    return (
        <>
            <Boxes />
            <div style={{ display: 'none' }}><SitesSelect /></div>

            {!profileToken ? <HomeLoader /> :
                <>
                    <ScheduleChart />

                    <Link to="/revenue"
                        style={{ display: 'block', margin: '2rem auto', width: '160px' }}
                    >
                        <Button variant="primary">See Revenue</Button>
                    </Link>
                </>
            }
        </>
    );
}

export default HomePage;
