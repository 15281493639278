import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../_slices/authSlice';

import { history } from '../../_helpers/history';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const auth = useSelector(selectAuth);
  const isAuth = auth.isAuthenticated;

    if (!isAuth) {
        // not logged in so redirect to login page with the return url
        // return <Navigate to="/login" state={{ from: history.location }} />
        return <Navigate to="/login" state={{ from: history.location }} />
    }

    return children;
}
