import styled from 'styled-components';
import PortalLogo from '../assets/images/FlexPortalLogo.svg';
import { Container, Nav, Navbar } from 'react-bootstrap';
import MainNavigation from '../components/navigation/main/mainNavigation';
import UserHeader from '../components/user/userHeader/userHeader';
import { Link } from 'react-router-dom';

const MainHeader = styled.header `
    margin-bottom: 2rem;
    // position: fixed;
    // width:100%;
`

export default function Header() {
    return (
        <MainHeader className="header">
            <Container fluid>
                <Navbar className="navbar navbar-expand-lg">
                    <Link className="navbar-brand header__logo-container" to="/">
                        <img className="logo logo--header" src={PortalLogo} alt="FlexPortal" />
                    </Link>
                    <Nav className="collapse navbar-collapse" id="navbarNav">
                        <MainNavigation />
                        <UserHeader />
                    </Nav>
                </Navbar >
            </Container>
        </MainHeader>

    )
}
