import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectProfileToken } from '../../../_slices/profileTokenSlice';
import ProfileTokenComponent from '../../auth/ProfileToken';

import { selectSelectedProfile } from '../../../_slices/sitesSlice';
import { baseAPIUrl } from '../../../config';
import axios from 'axios';
import moment from 'moment';
import SitesSelect from '../../sites/SitesSelect';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from 'react-bootstrap';



// Chart
import Chart from 'react-apexcharts';
import ScheduleLoader from '../../../_helpers/loaders/scheduleLoader';

// styles
import { ChartFilter, SitesContainer, ChartTitle, ChartDateWrapper } from '../_commons/chartStyles';
import { DatePickerComponent } from '../../FlexDatePicker/style';
import ArrowRight from '../../../assets/icons/arrow-right.svg'

import { ScheduleColorsWrapper } from '../_commons/scheduleColors';


function ScheduleChart(props) {
    const dispatch = useDispatch();

    const selectedProfile = useSelector(selectSelectedProfile);

    const [calendarOpen, setCalendarOpen] = useState(false);

    const initialToday = new Date();
    const [today, setToday] = useState(initialToday);
    const [todayLess1, setTodayLess1] = useState(new Date(initialToday.setDate(initialToday.getDate() - 1)));
    const todayLess2 = new Date(todayLess1);
    todayLess2.setDate(todayLess1.getDate() - 1);


    const startingDate = moment(todayLess2).format('YYYY-MM-DDT23:00:00');
    const endingDate = moment(todayLess1).format('YYYY-MM-DDT23:00:00');

    const [minTime, setMinTime] = useState(startingDate)
    const [maxTime, setMaxTime] = useState(endingDate)


    const handleDateChange = (date) => {
        const newDate = date || new Date();
        setToday(newDate);
        const newTodayLess1 = new Date(newDate);
        newTodayLess1.setDate(newDate.getDate());
        setTodayLess1(newTodayLess1);
    };

    const profileToken = useSelector(selectProfileToken);


    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteID = selectedProfile?.associated_site?.site_id ? selectedProfile?.associated_site?.site_id : firstProfile?.associated_site?.site_id;
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const ticks = moment(endingDate).diff(moment(startingDate), 'minutes') / 30

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${profileToken}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get(
                `${baseAPIUrl}/sites/${siteID}/scheduled-activity/site-services?starting_time=${startingDate}&ending_time=${endingDate}&format=charts`,
                config
            );
            setData(response.data.scheduled_activity);
            const generationResponse = await axios.get(
                `${baseAPIUrl}/sites/${siteID}/generation/actual-power?starting_time=${startingDate}&ending_time=${endingDate}&format=charts`,
                config);
            setData2(generationResponse.data.power_generation);
            console.log('scheduleResponse:::', response);
            console.log('generationResponse:::', generationResponse);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                setError(`${err.response.status} - ${err.response.statusText}`);
            } else if (err instanceof Error) {
                setError(`${err.message}`);
            } else {
                setError('An unknown error occurred while fetching data.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let timeInterval = 4 * 60 * 60 * 1000;
    let annotations = [];

    for (let time = moment(startingDate).valueOf(); time < moment(endingDate).valueOf(); time += timeInterval) {
        annotations.push({
            x: time,
            strokeDashArray: 0,
            borderColor: '#600C66',
            borderWidth: '1px'
        });
    }

    const handleButtonClick = () => {
        setCalendarOpen(false);
        setMinTime(startingDate);
        setMaxTime(endingDate);
        fetchData();
    };


    const humanDate = () => {
        let start = moment(startingDate).format('D MMMM yyyy')
        let end = moment(endingDate).format('D MMMM yyyy')
        return `Data from ${start} 11PM to ${end} 11PM`
    }

    const chartData = {
        options: {
            chart: {
                stacked: false,
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                    }
                }
            },
            fill: {
                colors: ["#2c8d30"], //#4CAF50
                type: 'solid',
            },
            tooltip: {
                enabled: false,
                shared: true,
                followCursor: true,
                intersect: false,
                inverseOrder: false,
                fillSeriesColor: false,
                theme: 'dark',
                marker: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '100%',
                }
            },
            stroke: {
                curve: 'stepline',
                width: 0,
            },
            legend: {
                show: true,
                showForNullSeries: false,
                showForZeroSeries: false,
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                decimalsInFloat: 2,
                showForNullSeries: false,
                title: {
                    text: "Capacity (MW)",
                    style: {
                        color: '#600C66',
                        fontSize: '14px'
                    }
                },
            },
            xaxis: {
                type: 'datetime',
                min: moment(minTime).valueOf(),
                max: moment(maxTime).valueOf(),
                tickAmount: moment(endingDate).diff(moment(startingDate), 'minutes') / 30,
                labels: {
                    rotate: -35,
                    rotateAlways: true,
                    formatter: function (value) {
                        var time = moment(value).format('HH:mm');
                        return time;
                    },
                },
            },
            grid: {
                show: true,
                borderColor: '#e5e5e5',
                position: 'front',
                row: {
                    colors: ['#e5e5e5', 'transparent'],
                    opacity: 0.2
                },
                column: {
                    colors: ['#ff0000', 'transparent'],
                    opacity: 0.9
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
            },
            annotations: {
                xaxis: annotations,
                yaxis: [
                    {
                        y: 0,
                        borderColor: '#555',
                        borderWidth: '1px',
                        strokeDashArray: 0,
                    }
                ]
            },
            noData: {
                text: 'There is no data for this site...'
            },
        },
        options2: {
            chart: {
                id: 'chart2',
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        pan: true,
                    },
                },
                zoom: {
                    enabled: true
                }
            },
            colors: [
                "#008FFB"
            ],
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                decimalsInFloat: 2,
                labels: {
                    style: {
                        colors: ['#008FFB'],
                        fontSize: '12px',
                    },
                },
                title: {
                    text: "Power (MW)",
                    style: {
                        color: '#008FFB',
                        fontSize: '14px'
                    }
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB',
                },
                axisTicks: {
                    show: true,
                    color: '#008FFB',
                    width: 1
                }
            },
            xaxis: {
                type: 'datetime',
                min: minTime,
                max: maxTime,
                tickAmount: moment(endingDate).diff(moment(startingDate), 'minutes') / 30,
                labels: {
                    rotate: -35,
                    rotateAlways: true,
                    formatter: function (value) {
                        var time = moment.utc(value).format('HH:mm');
                        return time;
                    },
                },
            },
            legend: {
                showForSingleSeries: true,
                showForNullSeries: false,
            },
        },
        series: []
    };


    return (
        <>

            <ProfileTokenComponent />
            <ChartTitle>
                <h2>Schedule</h2>
                <h3>{siteName}</h3>
            </ChartTitle>
            {
                error &&
                <Alert variant='danger'>
                    An error has occurred, please try again.
                </Alert>
            }
            {
                // data.length > 0 && <>
                    data && <>
                    <ChartFilter>
                        <SitesContainer>
                            <SitesSelect />
                        </SitesContainer>
                        <DatePickerComponent>
                            <DatePicker
                                selected={today}
                                onChange={date => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                            />

                            <button
                                onClick={handleButtonClick} className='btn btn-primary'
                            >
                                <img src={ArrowRight} alt="Update calendar" />
                            </button>

                        </DatePickerComponent>
                    </ChartFilter>

                    {loading ? (
                        <ScheduleLoader />
                    ) : (
                        <>
                            <ChartDateWrapper>
                                {humanDate()}
                            </ChartDateWrapper>

                            <ScheduleColorsWrapper>
                                <Chart
                                    id="schedule-chart"
                                    className="scheduleChart"
                                    type="area"
                                    height={props.ChartHeight ? props.ChartHeight : 540}
                                    options={{
                                        ...chartData.options,
                                    }}
                                    series={
                                        data
                                    }
                                />

                                <Chart
                                    id="range-chart-Schedule2"
                                    type="line"
                                    height={props.ChartHeightSecondary ? props.ChartHeightSecondary : 220}
                                    options={{
                                        ...chartData.options2,
                                    }}
                                    series={
                                        data2
                                    }
                                />
                            </ScheduleColorsWrapper>
                        </>
                    )}
                </>
            }
        </>
    )
}

export default ScheduleChart;
