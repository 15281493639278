import { useSelector } from 'react-redux';
import { selectProfileToken } from '../../_slices/profileTokenSlice';
import { selectSelectedProfile } from '../../_slices/sitesSlice';
import { useState, useEffect } from 'react';
import { baseAPIUrl } from '../../config';
import axios from 'axios';
import { ErrorType } from '../../_types/errorType';
import { Unit, Data } from '../../_types/unitType';


export default function Parameters() {
    const profileToken = useSelector(selectProfileToken);
    const selectedProfile = useSelector(selectSelectedProfile);
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteID = selectedProfile?.associated_site?.site_id ? selectedProfile?.associated_site?.site_id : firstProfile?.associated_site?.site_id;

    const [data, setData] = useState<Data | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ErrorType>(null);

    const fetchData = async () => {

        setLoading(true);
        setError(null);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${profileToken}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.get(`${baseAPIUrl}/sites/${siteID}/parameters/`, config);
            setData(response.data);
        }
        catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                setError(`Error: ${err.response.status} - ${err.response.statusText}`);
            } else if (err instanceof Error) {
                setError(`Error: ${err.message}`);
            } else {
                setError('An unknown error occurred while fetching data.');
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [siteID, profileToken]);

    const getUniqueUnitTypes = (units: { [key: string]: Unit }): string[] => {
        const unitValues = Object.values(units);
        const unitTypes = unitValues.map(unit => unit.unit_type);
        return Array.from(new Set(unitTypes));
    };

    const getUniqueServices = (units: { [key: string]: Unit }): string[] => {
        const unitValues = Object.values(units);
        const services = unitValues.flatMap(unit => unit.service);
        const transformedServices = services.map(service => {
            if (service === "DCL" || service === "DCR") {
                return "DC";
            } else if (service === "DRL" || service === "DRH") {
                return "DR";
            } else if (service === "DML" || service === "DMH") {
                return "DM";
            }
            return service;
        });
        return Array.from(new Set(transformedServices));
    };

    if (error) {
        // return <p>Error fetching data: {error}</p>;
        return <p>Loading...</p>;
    }

    // Check if data and data.units are defined
    if (!data || !data.units) {
        return <p>Loading...</p>;
    }

    return (
        <p>
            <strong>Nameplate Capacity:</strong> {data.nameplate_capacity} MW  <br />
            <strong>Type:</strong> {getUniqueUnitTypes(data.units).join(', ')}  <br />
            <strong>Services:</strong> {getUniqueServices(data.units).join(', ')} <br />

        </p>
    )
}
