import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/src/store'
import Select from 'react-select';

import { fetchSites, setSelectedProfile, selectSelectedProfile } from '../../_slices/sitesSlice';
// v0.3.5.7
// import { fetchSites, setSelectedProfile, selectSelectedProfile, getPersistedProfile } from '../../_slices/sitesSlice';
import { AuthorizedProfile } from '@/src/_types/sitesType';



export default function SitesSelect() {
    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const selectedProfile = useSelector(selectSelectedProfile);
    const firstProfile = selectedProfile?.authorized_profiles?.[0];
    const siteName = selectedProfile?.associated_site?.site_name ? selectedProfile?.associated_site?.site_name : firstProfile?.associated_site?.site_name;


    const [selectedOption, setSelectedOption] = useState(null);

    // DATA from Redux
    const data = useSelector((state: RootState) => state.selectedProfile.data);
    // Error and loading states from Redux
    const loading = useSelector((state: RootState) => state.selectedProfile.loading);
    const error = useSelector((state: RootState) => state.selectedProfile.error);



    // Fetch data using Redux on component mount
    useEffect(() => {
        dispatch(fetchSites());
    }, [dispatch]);


    const options = data?.authorized_profiles.map((profile: AuthorizedProfile) => ({
        value: profile.associated_site.site_id,
        label: profile.associated_site.site_name,
        data: profile,
    }));


    const handleSiteChange = (selectedOption: any) => {
        if (selectedOption && selectedOption.data) {
            dispatch(setSelectedProfile(selectedOption.data));
            // v0.3.5.7
            localStorage.setItem('selectedProfile', JSON.stringify(selectedOption.data));
        }

    };


    if (error) return <div>Error: {error}</div>;
    if (!data) return <div>No Data</div>;

    return (
        <>
            {data && (
                <>
                    <Select
                        isLoading={loading}
                        options={options}
                        onChange={handleSiteChange}
                        placeholder={siteName}
                        className='SitesSelector'
                    />
                </>
            )}
        </>
    );
}
