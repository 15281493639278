import { NavLink } from 'react-router-dom';
import { LinkItemProps } from '@/src/_types/navigationProps';

function NavItem(
    {
        link,
        text,
        isActive
    }: LinkItemProps
) {
    return (
        <li className="nav-item">
            <NavLink
                to={link}
                className={`nav-link ${isActive ? 'active' : ''}`}
            >
                <span>{text}</span>
            </NavLink>
        </li>
    )
}

export default NavItem;
