import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { baseAPIUrlAuth } from '../config';
import axios from 'axios';
import { loginSuccess } from '../_slices/authSlice';
import { Alert, Form, Button } from 'react-bootstrap';

import styled from 'styled-components';
// import logo from '../assets/images/logo.svg';
import PortalLogo from '../assets/images/FlexPortalLogo.svg';
import loginBG from '../assets/images/flex-bg.png';
import ControlRoom from '../assets/images/control-flex.jpg';

// styles
const LoginWrapper = styled.main`
    background: url(${loginBG}) no-repeat center center / cover;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
`

const LoginInner = styled.section`
    margin: auto;
    width: 100%;
    max-width:960px;

    .login-leftCol {
        background: url(${ControlRoom}) no-repeat center center / cover;
    }
`

const LogoWrapper = styled.div`
    img {
        max-width: 250px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`


function LoginPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.post(`${baseAPIUrlAuth}/authenticate/master-token`, {
                username: username,
                password: password
            },
                config
            );

            dispatch(loginSuccess({ ...response.data, username, password }));
            // Save login timestamp in localStorage
            const timestamp = Date.now();
            localStorage.setItem('lastLogin', timestamp);
            navigate('/'); // redirect
        } catch (error) {
            setError(true)
        }
    };

    return (
        <LoginWrapper className='container'>
            <LoginInner className='shadow bg-white'>
                <Form onSubmit={handleLogin}>
                    <div className='row'>
                        <div className="col-md-6 d-none d-md-block pe-0 login-leftCol"></div>
                        <div className="col-md-6 ps-5 pe-5 py-5">
                            <LogoWrapper className="d-flex justify-content-center align-items-center">
                                <img src={PortalLogo} alt="Flexitricity" />
                            </LogoWrapper>
                            <h3 className="mb-4">
                                Login
                            </h3>
                            {
                                error &&
                                <Alert variant='danger'>
                                    Login failed. Please check your credentials.
                                </Alert>
                            }

                            <Form.Group className="form-floating mb-3">
                                <Form.Control
                                    id="floatingInput"
                                    type="text"
                                    placeholder="username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                />
                                <Form.Label htmlFor="floatingInput">Username</Form.Label>
                            </Form.Group>

                            <Form.Group className="form-floating mb-4">
                                <Form.Control
                                    id="floatingPassword"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <Form.Label htmlFor="floatingPassword">Password</Form.Label>
                            </Form.Group>

                            <Button type="submit">Login</Button>
                        </div>
                    </div>
                </Form>
            </LoginInner>
        </LoginWrapper>
    );
}

export default LoginPage;
