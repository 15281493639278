import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/src/store';
import { fetchProfileToken } from '../../_slices/profileTokenSlice';
import { selectSelectedProfile } from '../../_slices/sitesSlice';



export default function ProfileTokenComponent() {
    const dispatch: AppDispatch = useDispatch<AppDispatch>();
    const selectedProfile = useSelector(selectSelectedProfile);
    const accessToken = useSelector((state: RootState) => state.profileToken.accessToken);
    const loading = useSelector((state: RootState) => state.profileToken.loading);
    const error = useSelector((state: RootState) => state.profileToken.error);

    useEffect(() => {
        if (selectedProfile?.profile_info?.profile_id) {
            dispatch(fetchProfileToken());
        }
    }, [selectedProfile, dispatch]);

    if (loading) return <></>;
    if (error) return <div>Authentication Error: {error}</div>;
    if (!accessToken) return <div>No Token</div>;
}
