import styled from "styled-components";
import iconCalendar from "../../assets/icons/calndar.svg";
import { colors } from "../../_styles/colors";

export const DatePickerComponent = styled.div `
    display: flex;
    margin-bottom: -1rem;
    position: relative;

    .btn {
        padding: .25rem .5rem;
        width:50px;
        height:52px;
    }

    .react-datepicker-wrapper {
        width: auto;
        display: flex;
        margin: 0 .25rem;
    }

    .react-datepicker-popper {
        z-index: 99;
    }
    .react-datepicker__input-container input {
        // max-width: 150px;
        width: 150px;
        border: 1px solid;
        border-color: #ccc;
        border-radius: 0;
        min-height: 52px;
        padding: .9rem .75rem .75rem 2.4rem;
        font-size: 1rem;
        background: #fff url(${iconCalendar}) no-repeat 6px center /24px;
    }

    .react-datepicker {
        border: 0;
        border-radius:0;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__navigation-icon::before {
        border-color: ${colors.primary.main};
        border-width: 1px 1px 0 0;
    }

    .react-datepicker__header {
        background-color: ${colors.primary.light};
        border-bottom: 0;
        border-radius: 0;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name,
    .react-datepicker__day {
        color: ${colors.primary.dark};
        border-radius: 0;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range {
        background-color: ${colors.primary.dark};
        color: ${colors.primary.contrastText};
        border-radius: 0;
        &:hover {
            background-color: ${colors.primary.light};
            color: ${colors.primary.dark};
        }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected {
        background-color: ${colors.primary.light};
    }
`;
