import {Card, Placeholder } from 'react-bootstrap';

export function LoaderInfoBox01() {
    return (
        <>
            <Placeholder as={Card.Title} animation="wave">
                <Placeholder xs={10} />
            </Placeholder>

            <Placeholder animation="wave">
                <Placeholder xs={11} /> {' '}
                <Placeholder.Button variant="light" xs={3} />
            </Placeholder>
        </>
    )
}


export function LoaderInfoBox02() {
    return (
        <>
            <Placeholder as={Card.Title} animation="wave">
                <Placeholder xs={10} />
            </Placeholder>

            <Placeholder animation="wave">
                <Placeholder xs={8} /> <Placeholder xs={6} /> {' '}
                <Placeholder xs={7} />
            </Placeholder>
        </>
    )
}

export function LoaderInfoBox03() {
    return (
        <>
            <Placeholder as={Card.Title} animation="wave">
                <Placeholder xs={10} />
            </Placeholder>
            <Placeholder animation="wave">
                <Placeholder xs={7} /> <Placeholder xs={6} /> {' '}
            </Placeholder>
        </>
    )
}
