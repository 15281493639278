import { Placeholder } from 'react-bootstrap';

export function HomeLoader() {
    return (
        <>
            <div style={{ marginTop: '3rem' }}>
                <Placeholder as="p" animation="glow" style={{ height: '65px' }}>
                    <Placeholder xs={4} style={{ height: '65px' }} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={3} />
                </Placeholder>

                <Placeholder as="p" animation="glow" style={{ height: '270px' }}>
                    <Placeholder xs={12} style={{ height: '270px' }} />
                </Placeholder>
            </div>

            <div style={{marginTop: '5rem'}}>
            <Placeholder as="p" animation="glow"  style={{ height: '40px' }}>
                <Placeholder xs={6}  style={{ height: '40px' }} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={4} />
            </Placeholder>

            <Placeholder as="p" animation="glow"  style={{ height: '260px' }}>
                <Placeholder xs={12} style={{ height: '260px' }} />
            </Placeholder>
        </div>
        </>
    )
}
