import Placeholder from 'react-bootstrap/Placeholder';

export default function ScheduleLoader() {
    return (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <Placeholder animation="glow">
                    <Placeholder xs={12} style={{ height: '40vh' }} />
                </Placeholder>
            </div>
            <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: '20vh' }} />
            </Placeholder>
        </>
    )
}
