import NavItem from "./navItem";
function MainNavigation() {
    return (

        <ul className="navbar-nav ms-auto">
            <NavItem
                link="/"
                text="Home"
            />
            <NavItem
                link="schedule"
                text="Schedule"
            />
            <NavItem
                link="revenue"
                text="revenue"
            />
        </ul>
    )
}

export default MainNavigation;
