import styled from "styled-components";
// import { boxType } from "@/src/_types/boxType";
import { colors } from "../../../_styles/colors";

// export const Box = styled.section<boxType>`
export const UserHeaderWrapper = styled.span`
    #collapsible-nav-dropdown {
        padding: 0.5rem;
        &:hover {
            opacity: .5;
        }
        &:after {display: none}
        img {
            width: 28px;
        }

        + .dropdown-menu {
            right: 0;
            left: auto;
            border-radius: 0;
            border: 0;
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

            .dropdown-item {
                color: ${colors.text.primary};
                font-size: .9rem;
                border-radius: 0;
                padding: .5rem 1rem;
                &:hover, &:active {
                    color: ${colors.primary.dark};
                    background: ${colors.primary.light};
                }
            }
        }
    }
`
